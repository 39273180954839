<template>
  <b-container fluid>
    <b-card no-body>
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <div class="m-2">
          <b-row v-if="creatable">
            <b-col cols="12">
              <div class="text-right">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  v-b-modal.scoreLockPasswordSaveModal
                  variant="success"
                >
                  <span class="text-nowrap text-right">
                    <feather-icon icon="PlusIcon" /> Thêm cán bộ
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-row :class="{'mt-1': creatable}">
            <b-col cols="12">
              <vue-good-table
                :columns="columns"
                :rows="dataSources"
                :pagination-options="paginationOptions"
                :line-numbers="true"
              >
                <div
                  slot="emptystate"
                  style="text-align: center; font-weight: bold"
                >
                  Không có bản ghi nào !
                </div>
                <template
                  slot="table-row"
                  slot-scope="props"
                >
                  <span v-if="props.column.field === 'action'">
                    <b-button
                      v-if="creatable"
                      variant="primary"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="onResetPassword(props.row)"
                    >
                      <feather-icon icon="RefreshCwIcon" />
                    </b-button>
                    <b-button
                      v-if="deletable"
                      variant="danger"
                      class="btn-icon"
                      style="margin-left: 6px"
                      size="sm"
                      @click="onDelete(props.row)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </span>

                  <!-- Column: Common -->
                  <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                </template>

                <!-- pagination -->
                <template
                  slot="pagination-bottom"
                  slot-scope="props"
                >
                  <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                      <span class="text-nowrap"> Hiển thị 1 đến </span>
                      <b-form-select
                        v-model="itemsPerPage"
                        :options="itemsPerPageOptions"
                        class="mx-1"
                        @input="(value) => props.perPageChanged({ currentPerPage: value })"
                      />
                      <span class="text-nowrap">của {{ props.total }} bản ghi</span>
                    </div>
                    <div>
                      <b-pagination
                        :value="1"
                        :total-rows="props.total"
                        :per-page="itemsPerPage"
                        class="mt-1 mb-0"
                        @input="(value) => props.pageChanged({ currentPage: value })"
                      />
                    </div>
                  </div>
                </template>
              </vue-good-table>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>

    <ScoreLockPasswordSave @succeed="getDataSourcesFromStore" />
  </b-container>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { hasPermissionForResource, isSystemAdmin } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getUser } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import ScoreLockPasswordSave from '@/views/score-lock/ScoreLockPasswordSave.vue'

export default {
  name: 'ScoreLockPassword',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    ScoreLockPasswordSave,
    BFormSelect,
    BContainer,
    BCol,
    BRow,
    BCard,
    BButton,
    BOverlay,
    BPagination,
    VueGoodTable,
  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      itemsPerPage: 10,
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
      columns: [
        {
          label: 'Tài khoản',
          field: 'username',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập tài khoản',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Cán bộ',
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập cán bộ',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Vai trò',
          field: 'roles',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            placeholder: 'Nhập vai trò',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: '',
          field: 'action',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ],
      hasPassword: 1,
    }
  },
  computed: {
    ...mapGetters({
      dataSources: 'scoreLockPassword/accounts',
    }),
    paginationOptions() {
      return {
        enabled: true,
        perPage: this.itemsPerPage,
      }
    },
    creatable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.CREATE, ResourceCode.SCORE_LOCK_PASSWORD)
    },
    deletable() {
      return this.isSystemAdmin() || hasPermissionForResource(PermissionCode.DELETE, ResourceCode.SCORE_LOCK_PASSWORD)
    },
  },
  async created() {
    this.$socket.emit('register_server_socket', `${this.user.accountId}-${this.user.orgCode}`)
    await this.getDataSourcesFromStore()
  },
  beforeDestroy() {
    // Hủy kết nối và xóa các lắng nghe sự kiện trước khi component bị hủy
    this.sockets.unsubscribe('response_create_score_lock_password_from_socket')
  },
  async mounted() {
    // Lắng nghe sự kiện từ server socket
    this.sockets.subscribe('response_create_score_lock_password_from_socket',  async (data) => {
      this.$bvModal.hide('scoreLockPasswordSaveModal')
      const dataParse = JSON.parse(data)
      let props = {}
      if (dataParse.status === 1) {
        props = {
          title: dataParse.message,
          icon: 'CheckIcon',
          variant: 'success',
        }
        await this.getDataSourcesFromStore()
      } else {
        props = {
          title: "Có lỗi xảy ra",
          icon: 'XCircleIcon',
          variant: 'danger',
        }
      }
      this.$toast({
        component: ToastificationContent,
        props,
      })
      this.isLoading = false
    })
  },
  methods: {
    ...mapActions({
      getAccounts: 'scoreLockPassword/getAccounts',
      deleteScoreLockPassword: 'scoreLockPassword/deleteScoreLockPassword',
      resetScoreLockPassword: 'scoreLockPassword/resetScoreLockPassword',
    }),
    isSystemAdmin,
    async getDataSourcesFromStore() {
      this.isLoading = true
      try {
        await this.getAccounts({ organizationId: this.user.orgId, type: this.hasPassword })
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    onResetPassword(account) {
      this.$swal({
        title: `Bạn chắc chắn muốn tạo lại mật khẩu cho <span class="text-danger">${account.name}</span>?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.isLoading = true
            try {
              const response = await this.resetScoreLockPassword(account.id)
              if (response) {
                const { isSuccessful, message } = response
                if (isSuccessful) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            } finally {
              this.isLoading = false
            }
          }
        })
    },
    onDelete(account) {
      this.$swal({
        title: `Bạn chắc chắn muốn xóa mật khẩu khóa điểm của cán bộ <span class="text-danger">${account.name}</span>?`,
        text: 'Bạn sẽ không thể khôi phục lại dữ liệu này!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '<i class="fa-solid fa-check"></i> Đồng ý',
        cancelButtonText: '<i class="fa-solid fa-times"></i> Hủy',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(async result => {
          if (result.value) {
            this.isLoading = true
            try {
              const response = await this.deleteScoreLockPassword(account.id)
              if (response) {
                const { isSuccessful, message } = response
                if (isSuccessful) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  await this.getAccounts({ organizationId: this.user.orgId, type: this.hasPassword })
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: message,
                      icon: 'XCircleIcon',
                      variant: 'danger',
                    },
                  })
                }
              }
            } catch (e) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `[${e.code}] ${e.message}`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            } finally {
              this.isLoading = false
            }
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
